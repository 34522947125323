import { Content } from "@/models/content";
import { ContentManager } from "@/services/contentManager";

export const day6: Content[] = [
    {
        id: "3e133754-1119-4de7-b739-0a5787c9222d",
        text: [
            "Hi <nick name>! Welcome back to wMammogram project!"
        ],
        textStyle: [
            ["text-h5", "text-center"]
        ],
        pinkie: {
            src: require("@/assets/pinkie/hello.png"),
            animation: "pinkie-hello",
            left: true
        }
    },
    {
        id: "63ac6d39-0cf8-4028-87ed-ce1dce8e83b0",
        text: [
            "Today, we’d like to provide information about clinics where you can get a mammogram and share the testimony of a survivor."
        ]
    },
    {
        id: "e71128ca-be13-4c07-bc36-b2839806eedd",
        text: [
            "We'd like to know if you have insurance, and if so, what insurance you have."
        ],
        question: {
            question: "This will only be used to provide clinic information that is relevant to you. Please choose one of these options:",
            answers: [
                "No insurance",
                "I have insurance with Health Partners, Blue Cross, Blue Shields, Medicaid, Medicare, and IHS.",
                "Others"
            ],
            correct: 1,
            text: [
                [
                    "We are able to provide clinic information even though you do not have health insurance."
                ],
                [
                    "Our health navigators will be able to help you find a clinic that takes your insurance for your mammography."
                ],
                [
                    "What kind of health insurance do you have?",
                    "Would you text us the name of your insurance? tel:605-202-1418 605-202-1418.",
                    "Our health navigators will be able to help you to find a clinic that takes your insurance for your mammogram, and let you know the results via a phone call."
                ]
            ]
        }
    },
    {
        id: "65641ef3-e46f-4aee-b1eb-4f1279bd4c39",
        text: [
            "Clinic information:",
            "<Wagner - Wagner Community Memorial Hospital Avera link:/directions/wagner>",
            "<Yankton - Avera Sacred Heart Hospital link:/directions/yankton>",
            "<Sioux Falls - Avera Breast Center link:/directions/sf>",
            "<Sioux Falls - Edith Sanford Breast Center link:/directions/sf-2>",
            "Don’t hesitate to contact our health navigator tel:605-202-1418 605-202-1418, if you need help to find clinics."
        ]
    },
    {
        id: "7492cc3b-4170-4cbb-b5eb-d1c7cff57ad0",
        question: {
            question: "Will you visit one of those clinics to get your breasts screened?",
            answers: [
                "Yes",
                "No"
            ],
            correct: 0,
            text: [
                [
                    "Great!",
                    "Would you let us know when your appointment would be, if you already made an appointment?",
                    "Our health navigator will help to remind your appointment! tel:605-202-1418 605-202-1418"
                ],
                [
                    "OK. Please let us know if you have any concerns about getting a mammogram.",
                    "We’d love to help you! tel:605-202-1418 605-202-1418",
                ]
            ]
        }
    },
    {
        id: "5dbd2173-442a-4a3b-88bf-76a6412b129f",
        video: {
            srcset: {
                hd: require("@/assets/videos/Survivors Karen and Tonya.mp4"),
            },
            drive: {
                sd: "1Nn5LDpU67yi3VT8VO1G1zQmWdEQrobia",
                hd: "1Amna0a-v4UWpBABhi8cDR001Elsi-dIe",
                uhd: "1HbAT2C9XdzewVMEoK8cLhPySRjQKeGXx"
            }
        }
    },
    {
        id: "8d36870e-84aa-459e-be4f-033b7c40de39",
        text: [
            "Great job!",
            "You will receive a notification later inform you how many pink ribbons you have received today."
        ]
    }
];

export const manager = new ContentManager(day6);

export default day6;